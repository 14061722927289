import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {MdOutlineKeyboardArrowDown} from "react-icons/md";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export const SelectToken = ({items, selected, setSelected, outline, icon}) => {

    return (
        <>
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button
                    className={classNames("relative inline-flex items-center bg-blue-400 text-sm sm:text-base hover:bg-blue-600 rounded-md px-2 sm:px-3 py-2 text-white justify-between",
                        outline ? "border border-white" : "",
                        icon ? "w-[140px] sm:w-[170px]" : "w-[120px] sm:w-[150px]"
                    )}>
                    {
                        icon &&
                        <img
                            className="flex-grow-0 w-6 h-5 sm:w-8 sm:h-7 rounded-full"
                            src={selected.icon}
                            alt=""
                        />
                    }
                    <span className="mx-3 flex-grow-0">{selected.name}</span>
                    <MdOutlineKeyboardArrowDown className="w-3 h-3 sm:w-5 sm:h-5 ml-auto"/>
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 w-auto mt-2 origin-top-right bg-blue-400 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {items.map((item, i) =>
                            <Menu.Item key={i}>
                                {({active}) => (
                                    <div
                                        onClick={() => setSelected(item)}
                                        className={classNames(
                                            active ? 'bg-blue-600 text-white' : 'bg-blue-400 text-white',
                                            'flex items-center px-2 sm:px-3 py-2 text-sm sm:text-base rounded-md cursor-pointer',
                                            outline ? "border border-white" : "",
                                            icon ? "w-[140px] sm:w-[170px]" : "w-[120px] sm:w-[150px]"
                                        )}
                                    >
                                        {
                                            icon &&
                                            <img
                                                className="flex-shrink-0 w-6 h-5 sm:w-8 sm:h-7 rounded-full"
                                                src={item.icon}
                                                alt=""
                                            />
                                        }
                                        <span className="mx-3">{item.name}</span>
                                    </div>
                                )}
                            </Menu.Item>
                        )}
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    );
}
