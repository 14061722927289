import React from 'react';
import ReactDOM from 'react-dom/client';
import {CookiesProvider} from 'react-cookie';
import webConfig from "./utils/webUtil";

import '@rainbow-me/rainbowkit/styles.css';
import {
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import {connectorsForWallets} from '@rainbow-me/rainbowkit';
import {
    metaMaskWallet,
    walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import {createConfig, WagmiProvider} from 'wagmi';
import {
    mainnet,
} from 'wagmi/chains';

import {
    QueryClientProvider,
    QueryClient,
} from "@tanstack/react-query";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const projectId = 'fe62b424c4ab666f47d64744e0b3dca0';

const connectors = connectorsForWallets(
    [
        {
            groupName: 'Recommended',
            wallets: [metaMaskWallet, walletConnectWallet],
        },
    ],
    {appName: 'MEMO', projectId: projectId},
);

const L1_NET = {
    id: webConfig.chain.L1_CHAIN_ID,
    name: webConfig.chain.L1_NAME,
    iconUrl: "./img/memo.png",
    nativeCurrency: {name: webConfig.chain.L1_NAME, symbol: webConfig.chain.L1_NAME, decimals: 18},
    rpcUrls: {
        default: {http: [webConfig.chain.ETH_L1_URL]},
    },
    blockExplorers: {
        default: {name: 'Metamemo', url: webConfig.chain.L1_EXPLORER_URL},
    },
    contracts: {
        "MemoChain-MEMO (MEMO)": {
            address: "0xeB8eec5a2dBf6e6f4Cc542ad31CCe706f8f80419",
        },
        "MEMO USDT (USDT)": {
            address: "0xD8c9b5332b8a584f974BA47e7694BBDfbF9C25c8",
        },
    },
};

const L2_NET = {
    id: webConfig.chain.L2_CHAIN_ID,
    name: webConfig.chain.L2_NAME,
    iconUrl: "./img/memo.png",
    nativeCurrency: {name: webConfig.chain.L2_NAME, symbol: webConfig.chain.L2_NAME, decimals: 18},
    rpcUrls: {
        default: {http: [webConfig.chain.ETH_L2_URL]},
    },
    blockExplorers: {
        default: {name: 'Metamemo', url: webConfig.chain.L2_EXPLORER_URL},
    },
    contracts: {
        "MemoChain-MEMO (MEMO)": {
            address: "0xeB8eec5a2dBf6e6f4Cc542ad31CCe706f8f80419",
        },
        "MEMO USDT (USDT)": {
            address: "0xD8c9b5332b8a584f974BA47e7694BBDfbF9C25c8",
        },
    },
};

const config = createConfig({
    connectors,
    chains: [mainnet, L1_NET, L2_NET],
});


const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <CookiesProvider>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <RainbowKitProvider modalSize="compact">
                        <App/>
                    </RainbowKitProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </CookiesProvider>
    </React.StrictMode>
);

reportWebVitals();
