import axios from "axios";
import {CgArrowsExchangeAltV} from "react-icons/cg";
import {ThreeDot} from 'react-loading-indicators';
import {useState, useEffect} from 'react';
import {useCookies} from "react-cookie";
import {useAccount} from "wagmi";

import {ConnectWallet} from '../components/ConnectWallet';
import {SelectToken} from "../components/SelectToken";
import Navbar from "../components/Navbar";
import {SwapError} from "./ErrorCode";
import webConfig from "../utils/webUtil";
import '../css/memo.css';


export default function Bridge() {

    const {address} = useAccount();

    const [cookies, setCookie, removeCookie] = useCookies(['uid', 'token', 'user', 'balance']);

    const [points, setPoints] = useState(null);

    const [activeTab, setActiveTab] = useState('Deposit');

    const [chains, setChains] = useState([
        {name: "USDT", icon: "/img/ethereum-eth.svg", coincode: "USDT(ERC20)", balance: "0.00"},
        {name: "USDT", icon: "/img/memo.svg", coincode: "USDT(MEMO)", balance: "0.00"},
        {name: "MEMO", icon: "/img/ethereum-eth.svg", coincode: "MEMO(ERC20)", balance: "0.00"},
        {name: "MEMO", icon: "/img/memo.svg", coincode: "MEMO(MEMO)", balance: "0.00"},
        {name: "CMEMO", icon: "/img/memo.svg", coincode: "CMEMO(MEMO)", balance: "0.00"},
    ]);

    useEffect(() => {
        const user = cookies.user;
        if (user) {
            setPoints(user.points);
        } else {
            setPoints(null);
        }
    }, [cookies.user]);

    const tokens = [
        {name: "USDT", icon: "/img/usdt.svg"},
        {name: "MEMO", icon: "/img/memo.svg"},
    ];

    const [token, setToken] = useState(tokens[0]);
    const [fromToken, setFromToken] = useState(chains[0]);
    const [toToken, setToToken] = useState(chains[1]);
    const [fromItems, setFromItems] = useState([chains[0]]);
    const [toItems, setToItems] = useState([chains[1]]);

    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState(0);

    const handleAmountChange = event => {
        const amount = event.target.value;
        setFromAmount(amount);
        setToAmount(Math.max(amount * instantRate * (1 - depositCoinFeeRate) - chainFee, 0));
        setErrorMessage(null);
    };

    useEffect(() => {
        const balance = cookies.balance;
        if (balance) {
            setChains([
                {name: "USDT", icon: "/img/ethereum-eth.svg", coincode: "USDT(ERC20)", balance: balance.usdterc20},
                {name: "USDT", icon: "/img/memo.svg", coincode: "USDT(MEMO)", balance: balance.usdtmemo},
                {name: "MEMO", icon: "/img/ethereum-eth.svg", coincode: "MEMO(ERC20)", balance: balance.memoerc20},
                {name: "MEMO", icon: "/img/memo.svg", coincode: "MEMO(MEMO)", balance: balance.memomemo},
                {name: "CMEMO", icon: "/img/memo.svg", coincode: "CMEMO(MEMO)", balance: balance.cmemomemo},
            ]);
        } else {
            setChains([
                {name: "USDT", icon: "/img/ethereum-eth.svg", coincode: "USDT(ERC20)", balance: "0.00"},
                {name: "USDT", icon: "/img/memo.svg", coincode: "USDT(MEMO)", balance: "0.00"},
                {name: "MEMO", icon: "/img/ethereum-eth.svg", coincode: "MEMO(ERC20)", balance: "0.00"},
                {name: "MEMO", icon: "/img/memo.svg", coincode: "MEMO(MEMO)", balance: "0.00"},
                {name: "CMEMO", icon: "/img/memo.svg", coincode: "CMEMO(MEMO)", balance: "0.00"},
            ]);
        }
    }, [cookies.balance]);

    useEffect(() => {
        if (activeTab === "Deposit") {
            if (token.name === "USDT") {
                setFromToken(chains[0]);
                setFromItems([chains[0]]);
                setToToken(chains[1]);
                setToItems([chains[1]]);
            } else {
                setFromToken(chains[2]);
                setFromItems([chains[2]]);
                setToToken(chains[3]);
                setToItems([chains[3], chains[4]]);
            }
        } else {
            if (token.name === "USDT") {
                setFromToken(chains[1]);
                setFromItems([chains[1]]);
                setToToken(chains[0]);
                setToItems([chains[0]]);
            } else {
                setFromToken(chains[3]);
                setFromItems([chains[3], chains[4]]);
                setToToken(chains[2]);
                setToItems([chains[2]]);
            }
        }
        setErrorMessage(null);
    }, [token, activeTab, chains]);

    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [instantRate, setInstantRate] = useState(null);
    const [depositCoinFeeRate, setDepositCoinFeeRate] = useState(null);
    const [chainFee, setChainFee] = useState(null);

    const host = "www.swftc.info";

    useEffect(() => {
        const getBaseInfo = async () => {
            const baseparams = {
                depositCoinCode: fromToken.coincode,
                receiveCoinCode: toToken.coincode,
            };
            try {
                const baseres = await axios.post(`https://${host}/api/v1/getBaseInfo`, baseparams);
                if (baseres.data.resCode === "800") {
                    setMinValue(baseres.data.data.depositMin);
                    setMaxValue(baseres.data.data.depositMax);
                    setInstantRate(baseres.data.data.instantRate);
                    setDepositCoinFeeRate(baseres.data.data.depositCoinFeeRate);
                    setChainFee(baseres.data.data.chainFee);
                    setToAmount(Math.max(fromAmount * baseres.data.data.instantRate * (1 - baseres.data.data.depositCoinFeeRate) - baseres.data.data.chainFee, 0));
                }
            } catch (error) {
                console.log("error", error);
            }
        }
        getBaseInfo();
        setErrorMessage(null);
    }, [fromToken, toToken]);

    const [bridgeLoading, setBirdgeLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleBridge = async () => {

        setBirdgeLoading(false);
        if (parseFloat(fromAmount) > parseFloat(fromToken.balance)) {
            setErrorMessage("Insufficient account balance");
            setBirdgeLoading(true);
            return;
        }

        const generateRandomString = () => {
            let randomString = '0x';
            while (randomString.length < 66) {
                randomString += Math.floor(Math.random() * 16).toString(16);
            }
            return randomString;
        };

        const orderparams = {
            equipmentNo: address.substring(0, 32),
            sourceType: "H5",
            userNo: "",
            sessionUuid: "",
            orderId: "",
            sourceFlag: "widget",
            depositCoinCode: fromToken.coincode,
            receiveCoinCode: toToken.coincode,
            depositCoinAmt: fromAmount,
            receiveCoinAmt: toAmount,
            destinationAddr: address,
            refundAddr: address
        };
        try {
            const order = await axios.post(`https://${host}/api/v2/accountExchange`, orderparams);
            if (order.data.resCode === "800") {
                const orderId = order.data.data.orderId;
                console.log("order", order.data);

                const hashparams = {
                    orderId: orderId,
                    depositTxid: generateRandomString()
                };
                const hashres = await axios.post(`https://${host}/api/v2/modifyTxId`, hashparams);
                if (hashres.data.resCode === "800") {
                    console.log("hash", hashres.data);
                    setErrorMessage("SUCCESS");
                } else {
                    console.log("hash error", hashres.data.resCode);
                }
            } else {
                console.log("order error", order.data.resCode);
                setErrorMessage(SwapError[order.data.resCode]);
            }
        } catch (error) {
            console.log(error);
        }

        setBirdgeLoading(true);
    }

    return (
        <div className='relative w-full h-screen body body-board-view'>
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-16">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="grid max-w-lg grid-cols-1 gap-4 sm:gap-8 mx-auto lg:mx-0 lg:gap-24 lg:max-w-none lg:grid-cols-2">
                        <div className="flex items-start">
                            <div className="text-left">
                                <h2 className="mt-16 sm:mt-32 text-xl sm:text-4xl font-bold text-white harry-letter">Bridge to Earn Yield
                                    + {webConfig.name} Points.</h2>
                                <p className="mt-4 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16">You will earn more points over time based on
                                    how much you bridge.</p>
                                <p className="mt-4 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16">{webConfig.name} points can be redeemed in
                                    May or July 2024.</p>
                                {
                                    address &&
                                    <p className="mt-12 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16">Your current points are: {points}</p>
                                }
                            </div>
                        </div>

                        <div className='mt-12 sm:mt-20 bg-opacity-85 bg-blue-500 rounded-xl p-5 text-white text-left max-w-[600px]'>
                            <div className='rounded-md p-1 grid grid-cols-2 bg-blue-600'>
                                <div
                                    onClick={() => setActiveTab('Deposit')}
                                    className={`text-sm sm:text-base rounded-md cursor-pointer border-white text-white ${activeTab === 'Deposit' ? 'bg-blue-500 py-2 border border-white text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Deposit
                                </div>
                                <div onClick={() => setActiveTab('Withdraw')}
                                     className={`text-sm sm:text-base rounded-md cursor-pointer border-white text-white ${activeTab === 'Withdraw' ? 'bg-blue-500 py-2 border border-white text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Withdraw
                                </div>
                            </div>
                            <div className='flex flex-row justify-between items-center mt-12 ml-2'>
                                <div>
                                    {
                                        minValue && maxValue &&
                                        `Range : ${minValue} ~ ${maxValue}`
                                    }
                                </div>
                                <SelectToken items={tokens} selected={token} setSelected={setToken} icon={false}/>
                            </div>
                            {
                                activeTab === 'Deposit' ?
                                    <div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-8 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={fromItems} selected={fromToken} setSelected={setFromToken} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="number"
                                                    className="appearance-none h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={fromAmount}
                                                    onChange={handleAmountChange}
                                                    placeholder="0"
                                                    min={minValue}
                                                    max={maxValue}
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {fromToken.balance}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center mt-5'>
                                            <div className='rounded-full bg-blue-400 border border-white'><CgArrowsExchangeAltV
                                                className='text-white w-10 h-10'/></div>
                                        </div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-5 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center py-1 rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={toItems} selected={toToken} setSelected={setToToken} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="text"
                                                    className="h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={toAmount}
                                                    placeholder="0"
                                                    disabled
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {toToken.balance}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            errorMessage ?
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px] text-red-300'>
                                                    {errorMessage}
                                                </div>
                                                :
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px]'>
                                                    You will receive {toAmount} {token.name} + Points
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-8 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={fromItems} selected={fromToken} setSelected={setFromToken} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="text"
                                                    className="h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={fromAmount}
                                                    onChange={handleAmountChange}
                                                    placeholder="0"
                                                    min={minValue}
                                                    max={maxValue}
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {fromToken.balance}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center mt-5'>
                                            <div className='rounded-full bg-blue-400 border border-white'><CgArrowsExchangeAltV
                                                className='text-white w-10 h-10'/></div>
                                        </div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-5 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center py-1 rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={toItems} selected={toToken} setSelected={setToToken} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="text"
                                                    className="h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={toAmount}
                                                    placeholder="0"
                                                    disabled
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {toToken.balance}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            errorMessage ?
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px] text-red-300'>
                                                    {errorMessage}
                                                </div>
                                                :
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px]'>
                                                    You will receive {toAmount} {token.name} + No rewards or yield. 14 Day Withdrawal Period
                                                </div>
                                        }
                                    </div>
                            }
                            {
                                address ?
                                    <>
                                        {
                                            bridgeLoading ?
                                                <button
                                                    className="w-full mt-10 sm:mt-12 bg-blue-400 text-white text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-md"
                                                    onClick={handleBridge} type="button">
                                                    Submit
                                                </button>
                                                :
                                                <button
                                                    className="w-full mt-10 sm:mt-12 bg-blue-400 text-white text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-md"
                                                    onClick={handleBridge} type="button" disabled>
                                                    <ThreeDot color="#32cd32" size="small" textColor=""/>
                                                </button>
                                        }
                                    </>
                                    :
                                    <div className="mt-10 sm:mt-12">
                                        <ConnectWallet buttonname='Connect Wallet' roundparam='md' bridge={true}/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
