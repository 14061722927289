import React, {useRef, useEffect, useState} from "react";
import {useCookies} from "react-cookie";

import {useAccount} from "wagmi";

import {bindInvite, getUserInfo} from "../utils/ApiService";

export default function EnterCode() {

    const {address} = useAccount();

    const [errorMessage, setErrorMessage] = useState(null);
    const [inputValues, setInputValues] = useState(Array(6).fill(""));
    const refs = useRef(Array(6).fill().map(() => React.createRef()));
    const submitButtonRef = useRef(null);

    const [cookies, setCookie, removeCookie] = useCookies(['uid', 'token', 'user']);

    const handleKeyDown = (index) => (event) => {
        const newInputValues = [...inputValues];

        if (event.key === "Backspace") {
            if (inputValues[index] === "" && index > 0) {
                newInputValues[index - 1] = "";
                setInputValues(newInputValues);
                setTimeout(() => {
                    refs.current[index - 1].focus();
                }, 0);
            } else {
                newInputValues[index] = "";
                setInputValues(newInputValues);
                setTimeout(() => {
                    refs.current[index].focus();
                }, 0);
            }
        } else if (event.key === "ArrowRight" && index < inputValues.length - 1) {
            setTimeout(() => {
                refs.current[index + 1].focus();
                const len = refs.current[index + 1].value.length;
                refs.current[index + 1].setSelectionRange(len, len);
            }, 0);
        } else if (event.key === "ArrowLeft" && index > 0) {
            setTimeout(() => {
                refs.current[index - 1].focus();
                const len = refs.current[index - 1].value.length;
                refs.current[index - 1].setSelectionRange(len, len);
            }, 0);
        } else if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
            if (inputValues[index] === "") {
                newInputValues[index] = event.key;
                setInputValues(newInputValues);
                if (index < inputValues.length - 1) {
                    setTimeout(() => {
                        refs.current[index + 1].focus();
                        const len = refs.current[index + 1].value.length;
                        refs.current[index + 1].setSelectionRange(len, len);
                    }, 0);
                }
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const paste = event.clipboardData.getData("text");
        if (paste.length === 6) {
            setInputValues(paste.split("").slice(0, 6));
            refs.current[5].focus();
            refs.current[5].setSelectionRange(1, 1);
        }
    };

    const onEnterClaim = async () => {
        const code = inputValues.join("");

        if (address) {
            const data = {
                "inviteCode": code
            }
            const response = await bindInvite(cookies.uid, cookies.token, data);
            if (response.result === 1) {
                const userResponse = await getUserInfo(cookies.uid, cookies.token);
                if (userResponse.data) {
                    setCookie('user', JSON.stringify(userResponse.data), {path: '/'});
                }
            }
            if (response.result === 1001) {
                setErrorMessage("Invalid invite code. Try another.");
            } else if (response.result === 1003) {
                setErrorMessage("You have already been invited.");
            }
        } else {
            setErrorMessage("Please connect your wallet.");
        }
    };

    useEffect(() => {
        if (refs.current[0]) refs.current[0].focus();
    }, []);

    let filled = inputValues.every(val => val !== "");

    useEffect(() => {
        if (!filled) {
            setErrorMessage(null);
        } else {
            submitButtonRef.current.focus();
        }
    }, [filled]);

    return (
        <div className="pt-10 lg:pt-20 overflow-hidden relative h-full">
            <div className="relative px-6 mx-auto max-w-lg sm:max-w-2xl lg:px-8 rounded-xl bg-opacity-85 bg-blue-500">
                <div className="flex flex-col pt-6 sm:pt-10 pb-12 sm:pb-20">
                    <div className="grid grid-cols-2 gap-8 sm:gap-10 rounded-md">
                        <div className="w-full h-2.5 sm:h-3.5 bg-blue-600 rounded-md"></div>
                        <div className="w-full h-2.5 sm:h-3.5 bg-blue-600 rounded-md"></div>
                    </div>
                    <div className="mt-12 sm:mt-16 text-base sm:text-xl font-semibold text-center text-white">Early Access Airdrop</div>
                    <div className="mt-5 sm:mt-10 text-sm sm:text-lg text-center text-white">Enter your invite code to claim your airdrop</div>
                    <div className="flex flex-row justify-center items-center mt-8 sm:mt-12 mx-2 sm:mx-10 space-x-2 sm:space-x-4">
                        {inputValues.map((value, i) => (
                            <input
                                key={i}
                                className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-50 text-blue-900 text-center text-sm sm:text-xl border border-[#85BAE5] rounded-md py-2 outline-none"
                                value={value}
                                maxLength="1"
                                ref={ref => refs.current[i] = ref}
                                onKeyDown={handleKeyDown(i)}
                                onPaste={handlePaste}
                            />
                        ))}
                    </div>
                    {
                        <div className="mt-3 sm:mt-5 text-sm sm:text-lg text-center text-red-300 h-[30px]">
                            {errorMessage}
                        </div>
                    }
                    <div className="flex items-center justify-center mt-8 sm:mt-12">
                        {
                            filled ?
                                <button
                                    ref={submitButtonRef}
                                    className="bg-transparent text-white text-sm sm:text-base font-medium hover:text-blue-900 py-2 px-4 border border-white-500 rounded-md focus:outline-none"
                                    onClick={onEnterClaim}
                                    type="button"
                                >
                                    Submit Invite Code
                                </button>
                                :
                                <button
                                    className="bg-transparent text-white text-sm sm:text-base font-medium py-2 px-4 rounded-md"
                                    type="button"
                                    disabled={true}
                                >
                                    Enter Invite Code
                                </button>
                        }
                    </div>
                    {/* <div className="grid grid-cols-1 sm:grid sm:grid-cols-2 rounded-md mt-6 sm:mt-10">
            <div className="text-sm sm:text-base text-white text-center sm:text-right">Already registered?&nbsp;</div>
            <div className="text-sm sm:text-base text-blue-900 font-medium cursor-pointer text-center sm:text-left" onClick={() => handleRegistered()}>Log in with your wallet</div>
          </div> */}
                </div>
            </div>
        </div>
    );
}
