import {FaTelegramPlane, FaDiscord, FaTwitter} from "react-icons/fa";
import {AiOutlineUser} from "react-icons/ai";
import {useRef, useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faCheck} from "@fortawesome/free-solid-svg-icons";

import {useAccount} from 'wagmi';

import Navbar from "../components/Navbar";
import {editUserInfo} from "../utils/ApiService";

import '../css/memo.css'


export default function Profile() {

    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();
    const {address} = useAccount();

    const [cookies, setCookie, removeCookie] = useCookies(['uid', 'token', 'user']);

    const [name, setName] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [username, setUsername] = useState(null);

    const [twitter, setTwitter] = useState(null);
    const [discord, setDiscord] = useState(null);
    const [telegram, setTelegram] = useState(null);


    const onSaveProfile = async () => {
        const data = {
            "nickName": username
        }
        await editUserInfo(cookies.uid, cookies.token, data);
        setCookie('user', {...cookies.user, nickName: username}, {path: '/'});

        navigate('/bridge');
    }

    const onCancelProfile = () => {
        // navigate('/bridge');
        window.history.back();
    }

    const fileInput = useRef();

    const handleImgClick = () => {
        fileInput.current.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAvatar(URL.createObjectURL(file));
        }
    }

    useEffect(() => {
        const user = cookies.user;
        if (user) {
            setName(user.nickName);
            setUsername(user.nickName)
            setAvatar(user.avatar);
            setTwitter(user.twitter);
            setDiscord(user.discord);
            setTelegram(user.telegram);
        } else {
            setName(null);
            setAvatar(null);
            setTwitter(null);
            setDiscord(null);
            setTelegram(null);
        }
    }, [cookies.user])

    const truncateWalletAddress = (address, startLength = 5, endLength = 4) => {
        if (address) {
            let startPart = address.substring(0, startLength);
            let endPart = address.substring(address.length - endLength);
            return `${startPart}.....${endPart}`;
        }
    }

    return (
        <div className='relative w-full h-screen body body-board-view'>
            <Navbar/>
            <div className="relative py-20 overflow-hidden sm:py-20">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="relative px-6 mx-auto max-w-lg lg:px-8 rounded-xl bg-opacity-85 bg-blue-500">
                        <div className='flex flex-col pb-8'>
                            <div className="relative w-full">
                                <img
                                    className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-20 w-20 sm:h-24 sm:w-24 rounded-full ring-2 ring-white hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-1"
                                    src={avatar} alt="" onClick={handleImgClick} style={{cursor: 'pointer'}}/>
                                <input
                                    ref={fileInput}
                                    type="file"
                                    style={{display: 'none'}}
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                <div className='mt-12 sm:mt-16 text-xl font-semibold text-center text-white'>{name}</div>
                                <div className="mt-4 flex flex-row flex-grow justify-center items-center">
                                    <div className='text-base text-center text-white mr-2'>{truncateWalletAddress(address)}</div>
                                    <div onClick={() => {
                                        navigator.clipboard.writeText(address).then(() => {
                                            setCopied(true);
                                            setTimeout(() => {
                                                setCopied(false);
                                            }, 500);
                                        })
                                    }}>
                                        <FontAwesomeIcon
                                            icon={copied ? faCheck : faCopy}
                                            className='w-4 h-4 text-white cursor-pointer'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 sm:mt-4 flex flex-col items-stretch">
                                <div className='text-sm sm:text-base text-left text-white'>UserName</div>
                                <div className="relative mt-2">
                                    <div className="absolute inset-y-0 flex items-center ml-3">
                                        <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                    </div>
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                        placeholder={name}
                                        className="text-sm sm:text-base font-medium bg-blue-400 placeholder:text-gray-200 block w-full rounded-md border-0 px-12 py-2 text-white text-3xl font-blod shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:outline-blue-500"
                                    />
                                </div>
                            </div>

                            <div className='text-base sm:text-lg font-medium text-left text-white mt-4 sm:mt-6'>Social Connections</div>

                            <div className="mt-2 sm:mt-4 flex flex-col items-stretch">
                                <div className='text-sm sm:text-base text-left text-white'>Twitter</div>
                                <div className="relative mt-2">
                                    <div className="absolute inset-y-0 flex items-center ml-3">
                                        <FaTwitter className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                    </div>
                                    <input type="text" style={{cursor: 'not-allowed'}} placeholder={twitter ? twitter : '--'}
                                           className="text-sm sm:text-base font-medium bg-blue-400 placeholder:text-gray-200 block w-full rounded-md border-0 px-12 py-2 text-white text-3xl font-blod shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:outline-blue-500"
                                           disabled/>
                                </div>
                            </div>

                            <div className="mt-2 sm:mt-4 flex flex-col items-stretch">
                                <div className='text-sm sm:text-base text-left text-white'>Discord</div>
                                <div className="relative mt-2">
                                    <div className="absolute inset-y-0 flex items-center ml-3">
                                        <FaDiscord className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                    </div>
                                    <input type="text" style={{cursor: 'not-allowed'}} placeholder={discord ? discord : '--'}
                                           className="text-sm sm:text-base font-medium bg-blue-400 placeholder:text-gray-200 block w-full rounded-md border-0 px-12 py-2 text-white text-3xl font-blod shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:outline-blue-500"
                                           disabled/>
                                </div>
                            </div>

                            <div className="mt-2 sm:mt-4 flex flex-col items-stretch">
                                <div className='text-sm sm:text-base text-left text-white'>Telegram</div>
                                <div className="relative mt-2">
                                    <div className="absolute inset-y-0 flex items-center ml-3">
                                        <FaTelegramPlane className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                    </div>
                                    <input type="text" style={{cursor: 'not-allowed'}} placeholder={telegram ? telegram : '--'}
                                           className="text-sm sm:text-base font-medium bg-blue-400 placeholder:text-gray-200 block w-full rounded-md border-0 px-12 py-2 text-white text-3xl font-blod shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:outline-blue-500"
                                           disabled/>
                                </div>
                            </div>

                            <div className="mt-10 grid grid-cols-2 gap-6">
                                <button
                                    className="bg-transparent text-white hover:text-blue-900 font-semibold py-2 px-4 border border-white-500 rounded-xl"
                                    onClick={onSaveProfile} type="button">
                                    Save
                                </button>
                                <button
                                    className="bg-transparent text-white hover:text-blue-900 font-semibold py-2 px-4 border border-white-500 rounded-xl"
                                    onClick={onCancelProfile} type="button">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
