import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {ThreeDot} from 'react-loading-indicators';
import {useAccount} from "wagmi";

import Navbar from "../components/Navbar";
import {rankList, recentList} from "../utils/ApiService";

import '../css/memo.css';


export default function LeaderBoard() {

    const {address} = useAccount();

    const [cookies] = useCookies(['uid', 'token', 'user']);

    const [rankData, setRankData] = useState([]);
    const [rankLoading, setRankLoading] = useState(false);

    const [recentData, setRecentData] = useState([]);
    const [recentLoading, setRecentLoading] = useState(false);

    const [myRank, setMyRank] = useState(-1);

    useEffect(() => {
        const fetchRankData = async () => {
            try {
                const response = await rankList();
                let data = response.data;

                setRankData(data);
                setRankLoading(true);
            } catch (error) {
                console.log(error);
            }
        }
        fetchRankData();
    }, []);

    useEffect(() => {
        if (address) {
            const ranking = rankData.findIndex(item => item.uid == cookies.uid);
            if (ranking >= 0) {
                setMyRank(ranking);
            }
        } else {
            setMyRank(-1);
        }
    }, [address, cookies.uid, rankData]);

    useEffect(() => {
        const fetchRecentData = async () => {
            try {
                const response = await recentList();
                let data = response.data;

                const moment = require('moment');

                data = data.map(data => {
                    const lastDay = new Date(data.createAt).toISOString();

                    const diffInMinutes = moment().diff(lastDay, 'minutes');
                    const diffInHours = moment().diff(lastDay, 'hours');
                    const diffInDays = moment().diff(lastDay, 'days');
                    const diffInMonths = moment().diff(lastDay, 'months');
                    const diffInYears = moment().diff(lastDay, 'years');

                    let lastDayAgo;

                    if (diffInYears >= 1) {
                        lastDayAgo = `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
                    } else if (diffInMonths >= 1) {
                        lastDayAgo = `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
                    } else if (diffInDays >= 1) {
                        lastDayAgo = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
                    } else if (diffInHours >= 1) {
                        lastDayAgo = `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
                    } else {
                        lastDayAgo = `${diffInMinutes} ${diffInMinutes === 1 ? 'min' : 'mins'} ago`;
                    }

                    return {...data, lastdayago: lastDayAgo};

                });

                setRecentData(data);
                setRecentLoading(true);
            } catch (error) {
                console.log(error);
            }
        }
        fetchRecentData();
    }, []);

    const limit_leaderboard = 15;
    const limit_recentjoins = 8;

    return (
        <div className='relative w-full h-screen body body-board-view'>
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-16">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="flex flex-col lg:flex-row justify-between items-center text-left">

                        <div className="flex flex-col items-stretch items-center text-left">
                            <h2 className="text-xl sm:text-4xl font-bold text-white">LeaderBoard</h2>
                            <p className="mt-4 sm:mt-4 text-sm sm:text-lg text-white">Bridge & Invite friends to rank up</p>
                            <div className="relative px-6 lg:px-8 mt-8 sm:mt-12 shadow-2xl rounded-xl bg-opacity-85 bg-blue-500">
                                <div
                                    className="mt-4 overflow-y-auto h-[400px] w-[300px] sm:h-[580px] sm:w-[600px] md:h-[580px] md:w-[770px] lg:h-[580px] lg:w-[770px] no-scroll">
                                    {
                                        !rankLoading ?
                                            <div className="flex flex-row items-center justify-center m-auto">
                                                <ThreeDot color="#32cd32" size="medium" textColor=""/>
                                            </div>
                                            :
                                            <>
                                                <table className="w-full text-left whitespace-nowrap text-white">
                                                    <thead className="typography-brand-heading-4 sticky top-0 text-camo-200 backdrop-blur-lg">
                                                    <tr className="text-xs sm:text-sm lg:text-base">
                                                        <th className="pr-2 sm:pr-8 py-3 pb-4">Rank</th>
                                                        <th className="px-4 sm:px-8 py-3 pb-4">Name</th>
                                                        <th className="px-4 sm:px-8 py-3 pb-4">Invited By</th>
                                                        <th className="pl-2 sm:pl-4 py-3 pb-4 text-right">Points</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="divide-x divide-transparent text-xs sm:text-sm lg:text-base">
                                                    {
                                                        address && myRank >= 0 &&
                                                        <tr>
                                                            <td className="pr-2 sm:pr-8 py-3 font-bold text-blue-900">{myRank + 1}</td>
                                                            <td className="flex items-center px-4 sm:px-8 py-3 space-x-1 sm:space-x-4 font-bold text-blue-900">
                                                                <img className="h-6 w-6 sm:h-12 sm:w-12 flex-none rounded-full bg-gray-50"
                                                                     src={rankData[myRank].avatar === '' ? "https://pbs.twimg.com/profile_images/1706231728283770880/13YYSkVe_400x400.png" : rankData[myRank].avatar}
                                                                     alt=""></img>
                                                                <div>{rankData[myRank].nickName.length > limit_leaderboard ? rankData[myRank].nickName.substring(0, limit_leaderboard) + '...' : rankData[myRank].nickName}</div>
                                                            </td>
                                                            <td className="px-4 sm:px-8 py-3 font-bold text-blue-900">
                                                                {rankData[myRank].inviter.length > limit_leaderboard ? rankData[myRank].inviter.substring(0, limit_leaderboard) + '...' : rankData[myRank].inviter}
                                                            </td>
                                                            <td className="pl-2 sm:pl-4 py-3 font-bold text-blue-900 text-right">{rankData[myRank].points}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        [...rankData]
                                                            .sort((a, b) => b.points - a.points)
                                                            .map((row, index) => (
                                                                <tr key={index}>
                                                                    <td className="pr-2 sm:pr-8 py-3">{index + 1}</td>
                                                                    <td className="flex items-center px-4 sm:px-8 py-3 space-x-1 sm:space-x-4">
                                                                        <img class="h-6 w-6 sm:h-12 sm:w-12 flex-none rounded-full bg-gray-50"
                                                                             src={row.avatar === '' ? "https://pbs.twimg.com/profile_images/1706231728283770880/13YYSkVe_400x400.png" : row.avatar}
                                                                             alt=""></img>
                                                                        <div>{row.nickName.length > limit_leaderboard ? row.nickName.substring(0, limit_leaderboard) + '...' : row.nickName}</div>
                                                                    </td>
                                                                    <td className="px-4 sm:px-8 py-3">
                                                                        {row.inviter.length > limit_leaderboard ? row.inviter.substring(0, limit_leaderboard) + '...' : row.inviter}
                                                                    </td>
                                                                    <td className="pl-2 sm:pl-4 py-3 text-right">{row.points}</td>
                                                                </tr>
                                                            ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </>
                                    }
                                </div>
                                <div class="sticky inset-0">
                                    <div class="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-blue-500 to-80%"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch items-center text-left">
                            <h2 className="mt-16 sm:mt-16 md:mt-16 lg:mt-0 text-xl sm:text-4xl font-bold text-white">Recent Joins</h2>
                            <p className="mt-4 sm:mt-4 text-sm sm:text-lg text-white">Bridge & Invite friends to rank up</p>
                            <div className="relative px-6 sm:px-6 md:px-6 lg:px-4 mt-8 sm:mt-12 shadow-2xl rounded-xl bg-opacity-85 bg-blue-500">
                                <div
                                    className="mt-4 overflow-y-auto h-[400px] w-[300px] sm:h-[580px] sm:w-[600px] md:h-[580px] md:w-[770px] lg:h-[580px] lg:w-[320px] no-scroll">
                                    {
                                        !recentLoading ?
                                            <div className="flex flex-col items-center justify-center m-auto">
                                                <ThreeDot color="#32cd32" size="medium" textColor=""/>
                                            </div>
                                            :
                                            <>
                                                <table className="w-full text-left whitespace-nowrap text-white">
                                                    <tbody className="pt-12 divide-x divide-transparent">
                                                    {
                                                        [...recentData]
                                                            .sort((a, b) => new Date(b.createAt) - new Date(a.createAt))
                                                            .map((row, index) => (
                                                                <tr key={index} className="text-xs sm:text-sm lg:text-base">
                                                                    <td className="md:pr-2 lg:pr-0 py-3">
                                                                        <img class="h-6 w-6 sm:h-12 sm:w-12 flex-none rounded-full bg-gray-50"
                                                                             src={row.avatar === '' ? "https://pbs.twimg.com/profile_images/1706231728283770880/13YYSkVe_400x400.png" : row.avatar}
                                                                             alt=""></img>
                                                                    </td>
                                                                    <td className="md:px-4 lg:px-2 py-3">
                                                                        <div>
                                                                            {row.nickName.length > limit_leaderboard ? row.nickName.substring(0, limit_leaderboard) + '...' : row.nickName}
                                                                        </div>
                                                                        <div className="md:text-sm lg:text-xs">
                                                                            Invited
                                                                            by {row.inviter.length > limit_recentjoins ? row.inviter.substring(0, limit_recentjoins) + '...' : row.inviter}
                                                                        </div>
                                                                    </td>
                                                                    <td className="md:pl-8 lg:pl-0 md:text-base lg:text-sm py-3 text-right">{row.lastdayago}</td>
                                                                </tr>
                                                            ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </>
                                    }
                                </div>
                                <div class="sticky inset-0">
                                    <div class="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-blue-500 to-80%"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
