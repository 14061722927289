import {ConnectWallet} from "./ConnectWallet";

import "../css/memo.css";


export default function EarlyAccess() {

    return (
        <div className="pt-10 lg:pt-20 overflow-hidden relative h-full">
            <div className="relative px-6 mx-auto max-w-lg sm:max-w-2xl lg:px-8 rounded-xl bg-opacity-85 bg-blue-500">
                <div className="flex flex-col pt-6 sm:pt-10 pb-12 sm:pb-20">
                    <div className="grid grid-cols-2 gap-8 sm:gap-10 rounded-md">
                        <div className="w-full h-2.5 sm:h-3.5 bg-blue-600 rounded-md"></div>
                        <div className="w-full h-2.5 sm:h-3.5 bg-blue-300 rounded-md"></div>
                    </div>
                    <div className="mt-12 sm:mt-16 text-sm sm:text-base font-medium text-center text-white">Early Access Members</div>
                    <div className="flex items-center justify-center mt-10"><img src="../img/early-access-foreground.svg" className="w-1/3"
                                                                                 alt=""></img></div>
                    <div className="flex items-center justify-center mt-10">
                        <ConnectWallet buttonname="Connect Wallet" roundparam='3xl' bridge={false}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
