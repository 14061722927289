import {BrowserRouter, Route, Routes} from 'react-router-dom';

import './App.css';
import Home from './home/Home';
import Airdrop from './airdrop/Airdrop';
import LeaderBoard from './leaderboard/LeaderBoard';
import Bridge from './bridge/Bridge';
import BridgeL2 from './bridge/BridgeL2';
import Profile from './home/Profile.js';
import Record from './home/Record.js';
import Docs from './docs/Docs.js';


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/airdrop' element={<Airdrop/>}></Route>
                <Route path='/leaderboard' element={<LeaderBoard/>}></Route>
                <Route path='/bridge' element={<Bridge/>}></Route>
                <Route path='/bridgeL2' element={<BridgeL2/>}></Route>
                <Route path='/profile' element={<Profile/>}></Route>
                <Route path='/record' element={<Record/>}></Route>
                <Route path='/docs' element={<Docs/>}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
