import axios from "axios";
import {useState, useEffect} from 'react';
import {useCookies} from "react-cookie";
import {ThreeDot} from 'react-loading-indicators';
import {useAccount} from "wagmi";
import {TbBrandItch} from "react-icons/tb";

import Navbar from "../components/Navbar";
import {pointRecord} from "../utils/ApiService";

import '../css/memo.css';


export default function Record() {

    const {address} = useAccount();
    const [cookies] = useCookies(['uid', 'token', 'user']);

    const [pointData, setPointData] = useState([]);
    const [pointLoading, setPointLoading] = useState(false);

    const [depositData, setDepositData] = useState([]);
    const [withdrawData, setWithdrawData] = useState([]);
    const [txLoading, setTxLoading] = useState(false);

    useEffect(() => {
        if (address) {
            const fetchPointData = async () => {
                const response = await pointRecord(cookies.uid, cookies.token);
                if (response.result === 1) {
                    let data = response.data;
                    data = data.map(data => {
                        const actionDate = new Date(data.time).toISOString();
                        return {...data, actiondate: actionDate};
                    });

                    setPointData(data);
                    setPointLoading(true);
                }
            };
            fetchPointData();
        } else {
            setPointData([]);
            setPointLoading(false);
        }
    }, [address, cookies.uid]);

    const host = "www.swftc.info";

    useEffect(() => {
        if (address) {
            const fetchTransactionData = async () => {
                const params = {
                    equipmentNo: address.substring(0, 32),
                    sourceType: "H5",
                    userNo: "",
                    sessionUuid: "",
                    orderId: "",
                    sourceFlag: "widget",
                    pageNo: 1,
                    pageSize: 100,
                    fromAddress: address
                }
                const response = await axios.post(`https://${host}/api/v2/queryAllTrade`, params);
                if (response.data.resCode === "800") {
                    const memoTypes = ["MEMO(MEMO)", "CMEMO(MEMO)", "USDT(MEMO)"];
                    const depositList = [];
                    const withdrawList = [];
                    const data = response.data.data.pageContent;
                    data.forEach(item => {
                        if (memoTypes.includes(item.toCoinCode)) {
                            depositList.push(item);
                        }
                        if (memoTypes.includes(item.fromCoinCode)) {
                            withdrawList.push(item);
                        }
                    });
                    setDepositData(depositList);
                    setWithdrawData(withdrawList);
                    setTxLoading(true);
                }
            };
            fetchTransactionData();
        } else {
            setDepositData([]);
            setWithdrawData([]);
            setTxLoading(false);
        }
    }, [address, cookies.uid]);

    const [activeTab, setActiveTab] = useState('Points');

    return (
        <div className='relative w-full h-screen body body-board-view'>
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-12">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="grid max-w-2xl gap-16 mx-auto lg:mx-0 lg:max-w-none grid-cols-1">
                        <div className="flex flex-col items-stretch items-center text-left">
                            {
                                activeTab === 'Points' &&
                                <p className="mt-8 text-lg sm:text-2xl font-bold text-white">Points Detail</p>
                            }
                            {
                                activeTab === 'Deposit' &&
                                <p className="mt-8 text-lg sm:text-2xl font-bold text-white">Deposit Detail</p>
                            }
                            {
                                activeTab === 'Withdraw' &&
                                <p className="mt-8 text-lg sm:text-2xl font-bold text-white">Withdraw Detail</p>
                            }
                            <div className='mt-6 rounded-md p-1 grid grid-cols-3 bg-blue-600 w-[300px] sm:w-[450px] text-sm sm:text-base'>
                                <div
                                    onClick={() => setActiveTab('Points')}
                                    className={`rounded-md cursor-pointer text-white ${activeTab === 'Points' ? 'bg-blue-500 py-2 border text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Points
                                </div>
                                <div onClick={() => setActiveTab('Deposit')}
                                     className={`rounded-md cursor-pointer text-white ${activeTab === 'Deposit' ? 'bg-blue-500 py-2 border text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Deposit
                                </div>
                                <div onClick={() => setActiveTab('Withdraw')}
                                     className={`rounded-md cursor-pointer text-white ${activeTab === 'Withdraw' ? 'bg-blue-500 py-2 border text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Withdraw
                                </div>
                            </div>
                            <div className="relative w-full px-6 items-stretch lg:px-8 mt-6 shadow-2xl rounded-xl bg-opacity-85 bg-blue-500">
                                {
                                    <>
                                        <div className="mt-4 overflow-y-auto h-[580px] no-scroll">
                                            {
                                                activeTab === 'Points' &&
                                                (
                                                    !pointLoading ?
                                                        <div className="flex flex-col items-center justify-center m-auto">
                                                            <ThreeDot color="#32cd32" size="medium" textColor=""/>
                                                        </div>
                                                        :
                                                        pointData.length !== 0 ?
                                                            <>
                                                                {
                                                                    <table
                                                                        className="w-full text-left whitespace-nowrap text-white text-sm sm:text-base">
                                                                        <thead
                                                                            className="typography-brand-heading-4 sticky top-0 text-camo-200 backdrop-blur-lg">
                                                                        <tr>
                                                                            <th className="py-3 pb-4">Sources of Points</th>
                                                                            <th className="pr-8 py-3 pb-4">Time</th>
                                                                            <th className="pl-4 py-3 pb-4 text-right">Points</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody className="divide-x divide-transparent">
                                                                        {
                                                                            [...pointData]
                                                                                // .sort((a, b) => b.points - a.points)
                                                                                .map((row, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="flex items-center py-3">
                                                                                            <div>
                                                                                                {
                                                                                                    row.action === 101 ? "Following Twitter" :
                                                                                                        row.action === 102 ? "Joined Discord" :
                                                                                                            row.action === 103 ? "Joined Telegram" :
                                                                                                                row.action === 104 ? ("Invited By " + (row.nickName.length > 15 ? row.nickName.substring(0, 15) + '...' : row.nickName)) :
                                                                                                                    row.action === 100 ? ("Invite User " + (row.nickName.length > 15 ? row.nickName.substring(0, 15) + '...' : row.nickName)) :
                                                                                                                        "Other"
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pr-8 py-3">
                                                                                            <div>{row.actiondate.substring(0, 10) + ' ' + row.actiondate.substring(11, 19)}</div>
                                                                                        </td>
                                                                                        <td className="pl-4 py-3 text-right">{row.points}</td>
                                                                                    </tr>
                                                                                ))
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                }
                                                            </>
                                                            :
                                                            <div className="flex justify-center mt-4 overflow-y-auto h-[580px]">
                                                                <div
                                                                    className='flex flex-col justify-center items-center text-white text-sm sm:text-base'>
                                                                    <TbBrandItch className='w-7 h-7 mb-4'/>
                                                                    Bridge & Invite friends to get airdrop
                                                                </div>
                                                            </div>
                                                )
                                            }
                                            {
                                                activeTab === 'Deposit' &&
                                                (
                                                    !txLoading ?
                                                        <div className="flex flex-col items-center justify-center m-auto">
                                                            <ThreeDot color="#32cd32" size="medium" textColor=""/>
                                                        </div>
                                                        :
                                                        depositData.length !== 0 ?
                                                            <>
                                                                {
                                                                    <table
                                                                        className="w-full text-left whitespace-nowrap text-white text-sm sm:text-base">
                                                                        <thead
                                                                            className="typography-brand-heading-4 sticky top-0 text-camo-200 backdrop-blur-lg">
                                                                        <tr>
                                                                            <th className="pr-16 py-3 pb-4">From</th>
                                                                            <th className="pr-16 py-3 pb-4">To</th>
                                                                            <th className="pr-16 py-3 pb-4">Amount</th>
                                                                            <th className="pl-8 py-3 pb-4 text-right">Time</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody className="divide-x divide-transparent">
                                                                        {
                                                                            [...depositData]
                                                                                .sort((a, b) => b.points - a.points)
                                                                                .map((row, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="pr-16">
                                                                                            <div>{row.fromCoinCode}</div>
                                                                                        </td>
                                                                                        <td className="pr-16 py-3">
                                                                                            <div>{row.toCoinCode}</div>
                                                                                        </td>
                                                                                        <td className="pr-16 py-3">
                                                                                            <div>{row.toCoinAmt}</div>
                                                                                        </td>
                                                                                        <td className="pl-8 py-3 text-right">
                                                                                            <div>{row.beginDate}</div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                }
                                                            </>
                                                            :
                                                            <div className="flex justify-center mt-4 overflow-y-auto h-[580px]">
                                                                <div
                                                                    className='flex flex-col justify-center items-center text-white text-sm sm:text-base'>
                                                                    <TbBrandItch className='w-7 h-7 mb-4'/>
                                                                    Bridge & Invite friends to get airdrop
                                                                </div>
                                                            </div>
                                                )
                                            }
                                            {
                                                activeTab === 'Withdraw' &&
                                                (
                                                    !txLoading ?
                                                        <div className="flex flex-col items-center justify-center m-auto">
                                                            <ThreeDot color="#32cd32" size="medium" textColor=""/>
                                                        </div>
                                                        :
                                                        withdrawData.length !== 0 ?
                                                            <>
                                                                {
                                                                    <table
                                                                        className="w-full text-left whitespace-nowrap text-white text-sm sm:text-base">
                                                                        <thead
                                                                            className="typography-brand-heading-4 sticky top-0 text-camo-200 backdrop-blur-lg">
                                                                        <tr>
                                                                            <th className="pr-11 py-3 pb-4">From</th>
                                                                            <th className="pr-12 py-3 pb-4">To</th>
                                                                            <th className="pr-24 py-3 pb-4">Amount</th>
                                                                            <th className="pr-8 py-3 pb-4">Submission Time</th>
                                                                            <th className="pl-4 py-3 pb-4 text-right">Cashing Time</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody className="divide-x divide-transparent">
                                                                        {
                                                                            [...withdrawData]
                                                                                .sort((a, b) => b.points - a.points)
                                                                                .map((row, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="pr-11">
                                                                                            <div>{row.fromCoinCode}</div>
                                                                                        </td>
                                                                                        <td className="pr-12 py-3">
                                                                                            <div>{row.toCoinCode}</div>
                                                                                        </td>
                                                                                        <td className="pr-24 py-3">
                                                                                            <div>{row.toCoinAmt}</div>
                                                                                        </td>
                                                                                        <td className="pr-8 py-3">
                                                                                            <div>{row.beginDate}</div>
                                                                                        </td>
                                                                                        <td className="pl-4 py-3 text-right">
                                                                                            <div>{row.beginDate}</div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                }
                                                            </>
                                                            :
                                                            <div className="flex justify-center mt-4 overflow-y-auto h-[580px]">
                                                                <div
                                                                    className='flex flex-col justify-center items-center text-white text-sm sm:text-base'>
                                                                    <TbBrandItch className='w-7 h-7 mb-4'/>
                                                                    Bridge & Invite friends to get airdrop
                                                                </div>
                                                            </div>
                                                )
                                            }
                                        </div>
                                        <div class="sticky inset-0">
                                            <div
                                                class="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-blue-500 to-80%"></div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
