import {useEffect, useState} from "react";
import {Disclosure} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {useCookies} from "react-cookie";
import {useAccount} from "wagmi";
import {ThreeDot} from 'react-loading-indicators';
import {Alchemy, Network} from "alchemy-sdk";

import Account from "./Account";
import {ConnectWallet} from "./ConnectWallet";
import {bindWallet, getUserInfo} from "../utils/ApiService";
import axios from "axios";
import webConfig from "../utils/webUtil"


const navigation = [
    {name: "Home", href: "/", current: true},
    {name: "Airdrop", href: "/airdrop", current: false},
    {name: "LeaderBoard", href: "/leaderboard", current: false},
    {
        name: "Bridge",
        href: ("Localhost" == webConfig.name || "Memo" == webConfig.name || "TestMemo" == webConfig.name) ? "/bridgeL2" : "/bridge",
        current: false
    },
    {name: "Docs", href: "/docs", current: false}
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export default function Navbar() {

    const [state, setState] = useState("Home");
    const {address} = useAccount();

    const [accountLoading, setAccountLoading] = useState(false);

    const [userInfo, setUserInfo] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['uid', 'token', 'user', 'balance']);

    const [memo, setMemo] = useState("0.00");
    const [usdt, setUsdt] = useState("0.00");

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (address) {
                console.log("connected");
                const uid = cookies.uid;
                if (!uid) {
                    console.log("login");
                    try {
                        setAccountLoading(true);
                        const response = await bindWallet(address);
                        if (response.data) {
                            const host = "https://scan.metamemo.one:8080/";
                            const usdterc20_contract = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
                            const memoerc20_contract = "0xeB8eec5a2dBf6e6f4Cc542ad31CCe706f8f80419";
                            const usdtmemo_contract = "0xD8c9b5332b8a584f974BA47e7694BBDfbF9C25c8";

                            const cmemomemo_res = await axios.post(`${host}/api?module=account&action=balance&address=${address}`);
                            let temp = cmemomemo_res.data.result / (10 ** 18);
                            const cmemomemo = (Math.floor(temp * 100) / 100).toFixed(2);

                            const memomemo_res = await axios.post(`${host}/api?module=account&action=tokenbalance&contractaddress=${memoerc20_contract}&address=${address}`);
                            temp = memomemo_res.data.result / (10 ** 18);
                            const memomemo = (Math.floor(temp * 100) / 100).toFixed(2);

                            const usdtmemo_res = await axios.post(`${host}/api?module=account&action=tokenbalance&contractaddress=${usdtmemo_contract}&address=${address}`);
                            temp = usdtmemo_res.data.result / (10 ** 18);
                            const usdtmemo = (Math.floor(temp * 100) / 100).toFixed(2);

                            const config = {
                                apiKey: "ekZhZsGjfWuK39pYW_YXSEcRKDN8amSN",
                                network: Network.ETH_MAINNET,
                            };
                            const alchemy = new Alchemy(config);
                            const tokenContractAddresses = [usdterc20_contract, memoerc20_contract];
                            const data = await alchemy.core.getTokenBalances(
                                address,
                                tokenContractAddresses
                            );
                            temp = data.tokenBalances[0].tokenBalance / (10 ** 6);
                            const usdterc20 = (Math.floor(temp * 100) / 100).toFixed(2);

                            temp = data.tokenBalances[1].tokenBalance / (10 ** 6);
                            const memoerc20 = (Math.floor(temp * 100) / 100).toFixed(2);

                            const balance = {
                                "memoerc20": memoerc20,
                                "usdterc20": usdterc20,
                                "cmemomemo": cmemomemo,
                                "memomemo": memomemo,
                                "usdtmemo": usdtmemo
                            };
                            setCookie('balance', balance, {path: '/'});

                            setCookie('uid', response.data.uid, {path: '/'});
                            setCookie('token', response.data.token, {path: '/'});

                            const userResponse = await getUserInfo(response.data.uid, response.data.token);
                            if (userResponse.data) {
                                setCookie('user', JSON.stringify(userResponse.data), {path: '/'});
                            }

                            setMemo(memomemo);
                            setUsdt(usdtmemo);
                            setUserInfo(userResponse.data);
                        }
                        setAccountLoading(false);
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    const user = cookies.user;
                    setUserInfo(user);
                    const balance = cookies.balance;
                    setMemo(balance.memomemo);
                    setUsdt(balance.usdtmemo);
                }
            } else {
                console.log("disconnected");
                console.log("logout");

                removeCookie('uid');
                removeCookie('token');
                removeCookie('user');
                removeCookie('balance');

                setUserInfo(null);
                setMemo("0.00");
                setUsdt("0.00");
            }
        };
        fetchUserDetails();
    }, [address, cookies.user]);

    useEffect(() => {
        if (window.location.pathname.includes("airdrop")) {
            setState("Airdrop");
        } else if (window.location.pathname.includes("leaderboard")) {
            setState("LeaderBoard");
        } else if (window.location.pathname.includes("bridge")) {
            setState("Bridge");
        } else if (window.location.pathname.includes("profile")) {
            setState("Profile");
        } else if (window.location.pathname.includes("record")) {
            setState("Record");
        } else if (window.location.pathname.includes("docs")) {
            setState("Docs");
        } else {
            setState("Home");
        }
    }, []);

    return (
        <Disclosure as="nav" className="">
            {({open}) => (
                <>
                    <div className="px-4 sm:px-8 mx-auto max-w-7xl mt-6">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                <Disclosure.Button
                                    className="relative inline-flex items-center justify-center p-2 text-white rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5"/>
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block w-6 h-6" aria-hidden="true"/>
                                    ) : (
                                        <Bars3Icon className="block w-6 h-6" aria-hidden="true"/>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex items-center justify-start hidden sm:flex sm:ml-0 sm:items-stretch">
                                <div className="items-center flex-shrink-0">
                                    <a href="/" className="flex items-center">
                                        <img className="w-28" src={webConfig.image.logo} alt=""/>
                                    </a>
                                </div>
                                <div className="items-end hidden ml-16 md:ml-24 sm:flex">
                                    <div className="space-x-4">
                                        {navigation.slice(1).map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.name === state ? "text-[#09BD90]" : "text-white hover:text-[#09BD90] transition-all duration-200",
                                                    "rounded-md px-1 md:px-2 text-sm md:text-base font-medium"
                                                )}
                                                aria-current={item.current ? "page" : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center static inset-auto gap-3">
                                {
                                    accountLoading ?
                                        <ThreeDot color="#32cd32" size="small" textColor=""/>
                                        :
                                        userInfo &&
                                        <>
                                            <div className="flex min-w-0 flex-col justify-center text-white lg:block hidden">
                                                <div
                                                    className="typography-brand-body-l-caps w-full text-camo-300 sm:max-md:whitespace-nowrap text-base">{userInfo.points} Points
                                                </div>
                                                {/*<div className="typography-brand-body flex items-center text-camo-400 text-base">*/}
                                                {/*    {memo}*/}
                      {/*                              <span className="mr-1 md:mr-2">*/}
                      {/*  <img className="w-6 h-6 mx-2" src="./img/memo.svg" alt=""></img>*/}
                      {/*</span>*/}
                      {/*                              {usdt}*/}
                      {/*                              <span>*/}
                      {/*<img className="w-5 h-5 mx-2" src="./img/usdticon.svg" alt=""></img>*/}
                      {/*</span>*/}
                      {/*                          </div>*/}
                                            </div>
                                            <Account/>
                                        </>
                                }
                                <ConnectWallet buttonname='Connect Wallet' roundparam='3xl' bridge={false}/>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        item.name === state ? "text-[#09BD90]" : "text-white hover:text-[#09BD90] transition-all duration-200",
                                        "block rounded-md px-4 py-2 text-xs"
                                    )}
                                    aria-current={item.current ? "page" : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
