import {FaTelegramPlane, FaDiscord} from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";

import Navbar from "../components/Navbar";

import "../css/memo.css";
import {useEffect} from "react";
import {useParams} from "react-router";
import {getUserInfo, myList, addPoint, getAuth} from "../utils/ApiService";
import {useCookies} from "react-cookie";
import webConfig from "../utils/webUtil";


export default function Home() {

    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['uid', 'token', 'user']);

    const followTwitter = () => {
        window.open("https://twitter.com/MemoLabsOrg");
    };

    const joinDiscord = () => {
        window.open("https://discord.com/invite/YG4Ydv2E7X");
    };

    const joinTelegram = () => {
        window.open("https://t.me/MemoLabsio");
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const auth = query.get("auth");
        if ("success" == auth) {
            //update user info
            getUserInfo(cookies.uid, cookies.token).then(userResponse => {
                if (userResponse.data) {
                    setCookie('user', JSON.stringify(userResponse.data), {path: '/'});
                    // jump task page
                    navigate('/airdrop');
                }
            });

        }


    }, ['update'])

    return (
        <div className="relative w-full h-full body body-board-view">
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-16">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="grid max-w-lg sm:max-w-2xl grid-cols-1 gap-4 sm:gap-16 mx-auto lg:mx-0 lg:gap-32 lg:max-w-none lg:grid-cols-2">
                        <div className="flex items-start">
                            {
                                <div className="text-left sm:ml-2">
                                    <h2 className="mt-16 sm:mt-32 text-xl sm:text-4xl font-bold text-white harry-letter">{webConfig.text.main}</h2>
                                    <p className="mt-4 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16 harry-small-letter">{webConfig.text.desc}</p>
                                    <div className="mt-12 flex flex-row items-center gap-5 text-white">
                                        <button
                                            className="mr-4 bg-white text-blue-600 text-sm sm:text-base hover:text-white hover:bg-blue-600 font-medium py-2 sm:py-3 px-4 sm:px-6 rounded-3xl"
                                            onClick={() => navigate("/bridge")} type="button">
                                            Bridge & Earn
                                        </button>
                                        <FaXTwitter className="w-6 h-6 sm:w-7 sm:h-7 hover:text-blue-900 cursor-pointer transition-all duration-300"
                                                    onClick={followTwitter}/>
                                        <FaDiscord className="w-6 h-6 sm:w-7 sm:h-7 hover:text-blue-900 cursor-pointer transition-all duration-300"
                                                   onClick={joinDiscord}/>
                                        <FaTelegramPlane
                                            className="w-6 h-6 sm:w-7 sm:h-7 hover:text-blue-900 cursor-pointer transition-all duration-300"
                                            onClick={joinTelegram}/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="pt-10 lg:pt-20 overflow-hidden relative h-full flex justify-center">
                            <img src={webConfig.image.main} alt={webConfig.name}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
