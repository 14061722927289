import {useState} from "react";


export const ClipboardButton = ({name, text}) => {

    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 500);
    };

    return (
        <button
            className="bg-blue-400 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium hover:text-white hover:bg-blue-600 px-2 sm:py-2 sm:px-4 rounded-3xl"
            onClick={copyToClipboard}
            type="button"
        >
            {isCopied ? <span style={{fontSize: "20px"}}>&#10003;</span> : name}
        </button>
    );
};
