import {Fragment} from "react";
import {useState, useEffect} from "react";
import {Menu, Transition} from "@headlessui/react";
import {useCookies} from "react-cookie";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export default function Account() {

    const [cookies] = useCookies(['uid', 'token', 'user']);
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        const user = cookies.user;
        if (user) {
            setAvatar(user.avatar);
        } else {
            setAvatar(null);
        }
    }, [cookies.user])

    return (
        <>
            <Menu as="div" className="relative ml-3 mr-4">
                <div>
                    <Menu.Button
                        className="relative flex rounded-full bg-gray-800 text-base outline-none ring-2 ring-white hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-1">
                        <span className="absolute -inset-1.5"/>
                        <span className="sr-only">Open user menu</span>
                        <img
                            className="h-10 w-10 rounded-full"
                            src={avatar}
                            alt=""
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-blue-500 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="/profile"
                                    className={classNames(active ? "bg-blue-600" : "", "block pl-4 py-2 text-sm text-white")}
                                >
                                    Profile
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="/record"
                                    className={classNames(active ? "bg-blue-600" : "", "block pl-4 py-2 text-sm text-white")}
                                >
                                    Record
                                </a>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    );
}
