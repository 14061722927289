import Navbar from "../components/Navbar";

import "../css/memo.css";


export default function Docs() {

    return (
        <div className="relative w-full h-full body body-board-view">
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-16">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="grid max-w-lg sm:max-w-2xl grid-cols-1 gap-4 sm:gap-16 mx-auto lg:mx-0 lg:gap-32 lg:max-w-none lg:grid-cols-2">

                    </div>
                </div>
            </div>
        </div>
    );
}
