import axios from "axios";
import {CgArrowsExchangeAltV} from "react-icons/cg";
import {ThreeDot} from 'react-loading-indicators';
import {useState, useEffect} from 'react';
import {useCookies} from "react-cookie";
import {useAccount, useWalletClient, useBalance, useConnect, useChainId, useSwitchChain} from "wagmi";
import {ConnectWallet} from '../components/ConnectWallet';
import {SelectToken} from "../components/SelectToken";
import Navbar from "../components/Navbar";
import webConfig from "../utils/webUtil";
import '../css/memo.css';
import {ethers, providers} from "ethers"
import optimism from "@eth-optimism/sdk";
import {Web3} from "web3";

export default function BridgeL2() {

    const optimism = require("@eth-optimism/sdk")


    const tokens = webConfig.chain.tokens;


    const [bridgeLoading, setBridgeLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const currentChainId = useChainId()
    const {chains, switchChain} = useSwitchChain()


    const [l1Balance, setL1Balance] = useState(0);
    const [l2Balance, setL2Balance] = useState(0);


    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState(0);
    const {data: accountData, address, isConnected} = useAccount()
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    const walletClient = useWalletClient()
    const [token, setToken] = useState(tokens[0]);

    const [cookies, setCookie, removeCookie] = useCookies(['uid', 'token', 'user', 'balance']);

    const [points, setPoints] = useState(null);

    const [activeTab, setActiveTab] = useState('Deposit');

    const CUSTOM_L1_URL = webConfig.chain.CUSTOM_L1_URL;
    const ETH_L1_URL = webConfig.chain.ETH_L1_URL;
    const ETH_L2_URL = webConfig.chain.ETH_L2_URL;
    const AddressManager = webConfig.chain.AddressManager;
    const L1CrossDomainMessengerProxy = webConfig.chain.L1CrossDomainMessengerProxy;
    const L1StandardBridgeProxy = webConfig.chain.L1StandardBridgeProxy;
    const L2OutputOracleProxy = webConfig.chain.L2OutputOracleProxy;
    const OptimismPortalProxy = webConfig.chain.OptimismPortalProxy;


    function getETH_L1_URL() {
        if (token.isCustom) {
            return CUSTOM_L1_URL;
        } else {
            return ETH_L1_URL;
        }
    }


    function handleSwitch(cid) {
        switchChain({chainId: cid})
    }


    const handleBridgeLayer = async () => {

        console.log("start...");
        console.log(address);

        let l1Provider, l2Provider;

        if (activeTab === "Deposit") {
            l1Provider = await new ethers.providers.Web3Provider(window.ethereum);
            l2Provider = await new ethers.providers.StaticJsonRpcProvider(ETH_L2_URL);
        } else {
            l1Provider = await new ethers.providers.StaticJsonRpcProvider(getETH_L1_URL());
            l2Provider = await new ethers.providers.Web3Provider(window.ethereum);
        }


        const l1Signer = l1Provider.getSigner(address)
        const l2Signer = l2Provider.getSigner(address)
        const l1ChainId = await l1Provider.getNetwork().then(network => network.chainId)
        const l2ChainId = await l2Provider.getNetwork().then(network => network.chainId)

        console.log("l1 chain id: " + l1ChainId + " , l2 chain id:" + l2ChainId)

        const l1Contracts = {
            StateCommitmentChain: ethers.constants.AddressZero,
            CanonicalTransactionChain: ethers.constants.AddressZero,
            BondManager: ethers.constants.AddressZero,
            AddressManager: AddressManager,
            L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
            L1StandardBridge: L1StandardBridgeProxy,
            OptimismPortal: OptimismPortalProxy,
            L2OutputOracle: L2OutputOracleProxy,
        }
        const bridges = {
            Standard: {
                l1Bridge: l1Contracts.L1StandardBridge,
                l2Bridge: '0x4200000000000000000000000000000000000010',
                Adapter: optimism.StandardBridgeAdapter
            },
            ETH: {
                l1Bridge: l1Contracts.L1StandardBridge,
                l2Bridge: '0x4200000000000000000000000000000000000010',
                Adapter: optimism.ETHBridgeAdapter
            }
        }
        const crossChainMessenger = new optimism.CrossChainMessenger({
            contracts: {
                l1: l1Contracts,
            },
            bridges: bridges,
            l1ChainId: l1ChainId,
            l2ChainId: l2ChainId,
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            bedrock: true,
        })


        try {
            const amount = ethers.utils.parseEther(fromAmount);


            let result = null;

            if (activeTab === "Deposit") {
                console.log("L1 -> L2 ...");
                console.log(amount.toString());


                if (token.isCustom) {

                    var value = Web3.utils.toWei(fromAmount, "ether")
                    var depositTxn2 = await crossChainMessenger.approveERC20(token.L1_TOKEN, token.L2_TOKEN, value)
                    await depositTxn2.wait()
                    var deposit = await crossChainMessenger.depositERC20(token.L1_TOKEN, token.L2_TOKEN, value)
                    result = await deposit.wait()

                } else {
                    const deposit = await crossChainMessenger.depositETH(amount);
                    result = await deposit.wait();
                }


            } else {

                // withdraw
                console.log("L2 -> L1 ...");
                console.log(amount.toString());

                if (token.isCustom) {
                    var value = Web3.utils.toWei(fromAmount, "ether")
                    var withdrawal = await crossChainMessenger.withdrawERC20(token.L1_TOKEN, token.L2_TOKEN, value);
                    await withdrawal.wait()
                    await crossChainMessenger.waitForMessageStatus(withdrawal.hash, optimism.MessageStatus.READY_TO_PROVE)
                    await crossChainMessenger.proveMessage(withdrawal.hash)
                    await crossChainMessenger.waitForMessageStatus(withdrawal.hash, optimism.MessageStatus.READY_FOR_RELAY)
                    await crossChainMessenger.finalizeMessage(withdrawal.hash)
                    result = await crossChainMessenger.waitForMessageStatus(withdrawal.hash, optimism.MessageStatus.RELAYED)
                } else {
                    const operation = await crossChainMessenger.withdrawETH(amount);
                    result = await operation.wait();

                }

            }

            if (result) {
                setErrorMessage("SUCCESS");
                setFromAmount("");
                setToAmount(0);
                handleBalance(); // update balance
            } else {
                setErrorMessage("Failed");
            }


            console.log("finish");
        } catch (error) {
            setErrorMessage("Failed");
        }

        setBridgeLoading(false)


    }

    useEffect(() => {
        const user = cookies.user;
        if (user) {
            setPoints(user.points);
        } else {
            setPoints(null);
        }
    }, [cookies.user]);


    const handleBalance = async () => {

        console.log("handleBalance...");

        setL1Balance(0);
        setL2Balance(0);

        if (token.isCustom) {

            const l2Provider = await new ethers.providers.StaticJsonRpcProvider(ETH_L2_URL);
            // const l2balance = await l2Provider.getBalance(address);
            // const l2balance = await l2Provider.getBalance(token.L2_TOKEN);
            const l1Provider = await new ethers.providers.StaticJsonRpcProvider(getETH_L1_URL());
            const erc20ABI = [{
                constant: true,
                inputs: [{name: "_owner", type: "address"}],
                name: "balanceOf",
                outputs: [{name: "balance", type: "uint256"}],
                type: "function"
            }, {inputs: [], name: "faucet", outputs: [], stateMutability: "nonpayable", type: "function"}]
            const l1ERC20 = new ethers.Contract(token.L1_TOKEN, erc20ABI, l1Provider.getSigner(address))
            const l1balance = await l1ERC20.balanceOf(address);


            const l2ERC20 = new ethers.Contract(token.L2_TOKEN, erc20ABI, l2Provider.getSigner(address))
            const l2balance = await l2ERC20.balanceOf(address);

            setL1Balance(Number(ethers.utils.formatEther(l1balance)).toFixed(5));
            setL2Balance(Number(ethers.utils.formatEther(l2balance)).toFixed(5));

        } else {

            const l1Provider = await new ethers.providers.StaticJsonRpcProvider(ETH_L1_URL);
            const l2Provider = await new ethers.providers.StaticJsonRpcProvider(ETH_L2_URL);
            const l1balance = await l1Provider.getBalance(address);
            const l2balance = await l2Provider.getBalance(address);
            setL1Balance(Number(ethers.utils.formatEther(l1balance)).toFixed(5));
            setL2Balance(Number(ethers.utils.formatEther(l2balance)).toFixed(5));

        }

    }

    useEffect(() => {
        handleBalance();
    }, [token]);

    useEffect(() => {
        console.log("=== address ===")
        console.log(address)
        console.log(walletClient);
    }, [address]);


    const handleAmountChange = event => {
        const amount = event.target.value;
        setFromAmount(amount);
        setToAmount(amount);
        setErrorMessage(null);
    };


    const handleBridge = async () => {

        if (!fromAmount || 0 == fromAmount) {
            setErrorMessage("Please enter the amount");
            return;
        }

        let minValue = 0;

        if (activeTab === "Deposit") {
            minValue = l1Balance;
        } else {
            minValue = l2Balance;
        }

        if (parseFloat(fromAmount) > minValue) {
            setErrorMessage("Insufficient account balance");
            return;
        }

        setBridgeLoading(true);


        handleBridgeLayer();

    }

    return (
        <div className='relative w-full h-screen body body-board-view'>
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-16">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="grid max-w-lg grid-cols-1 gap-4 sm:gap-8 mx-auto lg:mx-0 lg:gap-24 lg:max-w-none lg:grid-cols-2">
                        <div className="flex items-start">
                            <div className="text-left">
                                <h2 className="mt-16 sm:mt-32 text-xl sm:text-4xl font-bold text-white harry-letter">Bridge to Earn Yield
                                    + {webConfig.name} Points.</h2>
                                <p className="mt-4 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16">You will earn more points over time based on
                                    how much you bridge.</p>
                                <p className="mt-4 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16">{webConfig.name} points can be redeemed in
                                    May or July 2024.</p>
                                {
                                    address &&
                                    <p className="mt-12 sm:mt-12 text-sm sm:text-lg text-white pr-8 sm:pr-16">Your current points are: {points}</p>
                                }
                            </div>
                        </div>

                        <div className='mt-12 sm:mt-20 bg-opacity-85 bg-blue-500 rounded-xl p-5 text-white text-left max-w-[600px]'>
                            <div className='rounded-md p-1 grid grid-cols-2 bg-blue-600'>
                                <div
                                    onClick={() => setActiveTab('Deposit')}
                                    className={`text-sm sm:text-base rounded-md cursor-pointer border-white text-white ${activeTab === 'Deposit' ? 'bg-blue-500 py-2 border border-white text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Deposit
                                </div>
                                <div onClick={() => setActiveTab('Withdraw')}
                                     className={`text-sm sm:text-base rounded-md cursor-pointer border-white text-white ${activeTab === 'Withdraw' ? 'bg-blue-500 py-2 border border-white text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                >
                                    Withdraw
                                </div>
                            </div>
                            <div className='flex flex-row justify-between items-center mt-12 ml-2'>
                                <div>
                                    {
                                        minValue && maxValue &&
                                        `Range : ${minValue} ~ ${maxValue}`
                                    }
                                </div>
                                <SelectToken items={tokens} selected={token} setSelected={setToken} icon={false}/>
                            </div>
                            {
                                activeTab === 'Deposit' ?
                                    <div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-8 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={tokens} selected={token} setSelected={token} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="number"
                                                    className="appearance-none h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={fromAmount}
                                                    onChange={handleAmountChange}
                                                    placeholder="0"
                                                    min={minValue}
                                                    max={maxValue}
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {l1Balance}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center mt-5'>
                                            <div className='rounded-full bg-blue-400 border border-white'><CgArrowsExchangeAltV
                                                className='text-white w-10 h-10'/></div>
                                        </div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-5 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center py-1 rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={tokens} selected={token} setSelected={token} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="text"
                                                    className="h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={toAmount}
                                                    placeholder="0"
                                                    disabled
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {l2Balance}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            errorMessage ?
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px] text-red-600'>
                                                    {errorMessage}
                                                </div>
                                                :
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px]'>
                                                    You will receive {toAmount} {token.name} + Points
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-8 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={tokens} selected={token} setSelected={token} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="text"
                                                    className="h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={fromAmount}
                                                    onChange={handleAmountChange}
                                                    placeholder="0"
                                                    min={minValue}
                                                    max={maxValue}
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {l2Balance}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center mt-5'>
                                            <div className='rounded-full bg-blue-400 border border-white'><CgArrowsExchangeAltV
                                                className='text-white w-10 h-10'/></div>
                                        </div>
                                        <div className='bg-blue-400 rounded-md p-2 flex flex-row mt-5 gap-5 pr-4 items-center justify-between'>
                                            <div className='flex flex-row gap-2 items-center py-1 rounded-md cursor-pointer text-sm sm:text-base'>
                                                <SelectToken items={tokens} selected={token} setSelected={token} icon={true}/>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <input
                                                    type="text"
                                                    className="h-8 w-20 sm:w-40 sm:h-10 placeholder:text-white bg-blue-400 text-blue-900 text-right text-white text-sm sm:text-base font-medium border-none rounded-md py-2 outline-none"
                                                    value={toAmount}
                                                    placeholder="0"
                                                    disabled
                                                />
                                                <div className='text-xs sm:text-sm'>
                                                    Balance: {l1Balance}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            errorMessage ?
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px] text-red-300'>
                                                    {errorMessage}
                                                </div>
                                                :
                                                <div className='flex ml-2 mt-8 text-left sm:mt-12 text-sm sm:text-sm h-[50px]'>
                                                    You will receive {toAmount} {token.name} + No rewards or yield. 14 Day Withdrawal Period
                                                </div>
                                        }
                                    </div>
                            }
                            {
                                address ? (((activeTab === 'Deposit' && currentChainId == webConfig.chain.L1_CHAIN_ID) || (activeTab === 'Withdraw' && currentChainId == webConfig.chain.L2_CHAIN_ID)) ?
                                        <>
                                            {
                                                bridgeLoading ?
                                                    <button
                                                        className="w-full mt-10 sm:mt-12 bg-blue-400 text-white text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-md"
                                                        type="button" disabled>
                                                        <ThreeDot color="#32cd32" size="small" textColor=""/>
                                                    </button>
                                                    :
                                                    <button
                                                        className="w-full mt-10 sm:mt-12 bg-blue-400 text-white text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-md"
                                                        onClick={handleBridge} type="button">
                                                        Submit
                                                    </button>
                                            }
                                        </> : <>
                                            <button
                                                className="w-full mt-10 sm:mt-12 bg-blue-400 text-white text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-md"
                                                onClick={() => {
                                                    handleSwitch(activeTab === 'Deposit' ? webConfig.chain.L1_CHAIN_ID : webConfig.chain.L2_CHAIN_ID)
                                                }}
                                                type="button">
                                                Switch To {activeTab === 'Deposit' ? "L1" : "L2"}
                                            </button>
                                        </>)
                                    :
                                    <div className="mt-10 sm:mt-12">
                                        <ConnectWallet buttonname='Connect Wallet' roundparam='md' bridge={true}/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
