import web_config from './web_config.json';

function getWebConfig() {
    for (let i = 0; i < web_config.length; i++) {
        const webConfig = web_config[i];
        if (window.location.host == webConfig.host) {
            return webConfig;
        }
    }
    return web_config[0];
}

export default getWebConfig();
