import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAccount} from "wagmi";
import {ConnectButton} from "@rainbow-me/rainbowkit";


export const ConnectWallet = ({buttonname, roundparam, bridge}) => {

    const navigate = useNavigate();
    const {address} = useAccount();

    useEffect(() => {
        if (!address) {
            if (window.location.href.includes("profile") || window.location.href.includes("record")) {
                navigate("/airdrop");
            }
        }
    }, [address, navigate]);

    return (
        <ConnectButton.Custom>
            {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                return (
                    <div
                        {...(!ready && {
                            "aria-hidden": true,
                            "style": {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    bridge ?
                                        <button
                                            className={`w-full bg-blue-400 text-white text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-${roundparam}`}
                                            onClick={openConnectModal} type="button">
                                            {buttonname}
                                        </button>
                                        :
                                        <button
                                            className={`w-full bg-white text-blue-600 text-sm sm:text-base font-medium hover:text-white hover:bg-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-${roundparam}`}
                                            onClick={openConnectModal} type="button">
                                            {buttonname}
                                        </button>
                                );
                            }
                            return (
                                <div
                                    className={`flex gap-3 bg-transparent border border-white text-white text-sm sm:text-base hover:text-blue-600 py-2 sm:py-3 px-4 sm:px-6 rounded-${roundparam}`}>
                                    <button
                                        className="bg-transparent"
                                        onClick={openChainModal}
                                        style={{display: "flex", alignItems: "center"}}
                                        type="button"
                                    >
                                        {chain.hasIcon && (
                                            <div
                                                style={{
                                                    background: chain.iconBackground,
                                                    width: 24,
                                                    height: 24,
                                                    borderRadius: 999,
                                                    overflow: "hidden",
                                                    marginRight: 4,
                                                }}
                                            >
                                                {chain.iconUrl && (
                                                    <img
                                                        alt={chain.name ?? "Chain icon"}
                                                        src={chain.iconUrl}
                                                        style={{width: 24, height: 24}}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </button>
                                    <button
                                        className="bg-transparent text-white text-sm sm:text-base hover:text-blue-600 font-medium"
                                        onClick={openAccountModal}
                                        type="button">
                                        {account.displayName}
                                        <span className="hidden md:inline">
                      {account.displayBalance ? ` (${account.displayBalance})` : ""}
                    </span>
                                    </button>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};
