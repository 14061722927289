import {useEffect, useState} from "react";
import {AiOutlineUser} from "react-icons/ai";
import {AiOutlineArrowDown} from "react-icons/ai";
import {useCookies} from "react-cookie";
import {ThreeDot} from 'react-loading-indicators';
import {useAccount} from "wagmi";

import Navbar from "../components/Navbar";
import EnterCode from "../components/EnterCode";
import EarlyAccess from "../components/EarlyAccess";
import {ClipboardButton} from "../components/ClipboardButton";
import {getUserInfo, myList, addPoint, getAuth} from "../utils/ApiService";

import "../css/memo.css";
import webConfig from "../utils/webUtil";


export default function Airdrop() {

    const {address} = useAccount();

    const [cookies, setCookie] = useCookies(['uid', 'token', 'user']);

    const [activeTab, setActiveTab] = useState("Task");
    const [twitter, setTwitter] = useState(null);
    const [discord, setDiscord] = useState(null);
    const [telegram, setTelegram] = useState(null);
    const [points, setPoints] = useState(null);
    const [parent, setParent] = useState(null);
    const [inviteCode, setInviteCode] = useState(null);

    const [invite, setInvite] = useState([]);
    const [inviteLoading, setInviteLoading] = useState(false);

    const [entire, setEntire] = useState(false);

    useEffect(() => {
        if (address) {
            const fetchInviteData = async () => {
                const response = await myList(cookies.uid, cookies.token);
                if (response.result === 1) {
                    let data = response.data;

                    setInvite(data);
                    setInviteLoading(true);
                }
            }
            fetchInviteData();
        } else {
            setInvite([]);
            setInviteLoading(false);
        }
    }, [address, cookies.uid]);

    useEffect(() => {
        const user = cookies.user;
        if (user) {
            setTwitter(user.twitter);
            setDiscord(user.discord);
            setTelegram(user.telegram);
            setPoints(user.points);
            setParent(user.parentUid);
            setInviteCode(user.inviteCode);
            setEntire(true);
        } else {
            setTwitter(null);
            setDiscord(null);
            setTelegram(null);
            setPoints(null);
            setParent(null);
            setInviteCode(null);
            setEntire(null);
        }
    }, [cookies.user]);

    const followTwitter = async () => {
        // change to auth
        const userResponse = await getAuth(cookies.uid, cookies.token, '101');
        if (userResponse.result === 1) {
            window.open(userResponse.data.authUrl);
        }

        // window.open("https://twitter.com/MemoLabsOrg");
        // const response = await addPoint(cookies.uid, cookies.token, 101);
        // if (response.result === 1) {
        //   const userResponse = await getUserInfo(cookies.uid, cookies.token);
        //   if (userResponse.data) {
        //     setCookie('user', JSON.stringify(userResponse.data), { path: '/' });
        //   }
        // }
    };

    const joinDiscord = async () => {
        window.open("https://discord.com/invite/YG4Ydv2E7X");
        const response = await addPoint(cookies.uid, cookies.token, 102);
        if (response.result === 1) {
            const userResponse = await getUserInfo(cookies.uid, cookies.token);
            if (userResponse.data) {
                setCookie('user', JSON.stringify(userResponse.data), {path: '/'});
            }
        }
    };

    const joinTelegram = async () => {
        window.open("https://t.me/MemoLabsio");
        const response = await addPoint(cookies.uid, cookies.token, 103);
        if (response.result === 1) {
            const userResponse = await getUserInfo(cookies.uid, cookies.token);
            if (userResponse.data) {
                setCookie('user', JSON.stringify(userResponse.data), {path: '/'});
            }
        }
    };

    return (
        <div className='relative w-full h-screen body body-board-view'>
            <Navbar/>
            <div className="relative py-8 overflow-hidden sm:py-16">
                <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                    {
                        !address ?
                            <EarlyAccess/>
                            :
                            entire ?
                                (!parent ?
                                    <EnterCode/>
                                    :
                                    <>
                                        <div className="flex flex-col items-stretch items-center text-left">
                                            <p className="text-sm sm:text-lg text-white mb-4">Your current points are: {points}</p>
                                            <div className='mt-4 rounded-md p-1 grid grid-cols-2 bg-blue-600 w-[250px] sm:w-[300px]'>
                                                <button
                                                    onClick={() => setActiveTab('Task')}
                                                    className={`text-sm sm:text-base rounded-md cursor-pointer border-white text-white ${activeTab === 'Task' ? 'bg-blue-500 py-2 border border-white text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                                >
                                                    Task
                                                </button>
                                                <button onClick={() => setActiveTab('Invitation')}
                                                        className={`text-sm sm:text-base rounded-md cursor-pointer border-white text-white ${activeTab === 'Invitation' ? 'bg-blue-500 py-2 border border-white text-center' : 'flex items-center justify-center hover:bg-blue-600 transition-all duration-300 hover:border'}`}
                                                >
                                                    Invitation
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            activeTab === 'Task' ?
                                                <div
                                                    className="flex flex-col relative px-6 lg:px-8 mt-8 sm:mt-12 shadow-2xl rounded-xl bg-opacity-85 bg-blue-500">
                                                    <div
                                                        className="flex flex-row text-white text-xl sm:text-2xl font-bold mt-4 mb-6 sm:mt-6 sm:mb-12">
                                                        To Claim Your Airdrop
                                                    </div>
                                                    <div className="overflow-y-auto h-[330px] sm:h-[390px]">
                                                        <div className="flex flex-row text-white mb-10 items-center">
                                                            <div className="flex-grow flex flex-row gap-3 sm:gap-5 items-center">
                                                                <div
                                                                    className="rounded-md bg-blue-400 text-white w-9 h-9 sm:w-14 sm:h-14 flex justify-center items-center text-center text-2xl"
                                                                    style={{lineHeight: '3rem'}}>&gt;</div>
                                                                <div className="text-xs sm:text-base font-semibold w-[140px] sm:w-auto">
                                                                    Get Invite Code
                                                                </div>
                                                            </div>
                                                            <ClipboardButton name={inviteCode} text={inviteCode}/>
                                                        </div>
                                                        <div className="flex flex-row text-white mb-10 items-center">
                                                            <div className="flex-grow flex flex-row gap-3 sm:gap-5 items-center">
                                                                <div
                                                                    className="rounded-md bg-blue-400 text-white w-9 h-9 sm:w-14 sm:h-14 flex justify-center items-center text-center text-2xl"
                                                                    style={{lineHeight: '3rem'}}>&gt;</div>
                                                                <div className="text-xs sm:text-base font-semibold w-[140px] sm:w-auto">
                                                                    Follow @MemoLabsOrg On Twitter
                                                                </div>
                                                            </div>
                                                            {
                                                                twitter ?
                                                                    <button
                                                                        className="bg-blue-600 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium sm:py-2 sm:px-4 rounded-3xl"
                                                                        type="button" disabled>
                                                                        Following
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className="bg-blue-400 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium hover:text-white hover:bg-blue-600 px-2 sm:py-2 sm:px-4 rounded-3xl"
                                                                        onClick={followTwitter} type="button">
                                                                        Follow
                                                                    </button>
                                                            }
                                                        </div>
                                                        <div className="flex flex-row text-white mb-10 items-center">
                                                            <div className="flex-grow flex flex-row gap-3 sm:gap-5 items-center">
                                                                <div
                                                                    className="rounded-md bg-blue-400 text-white w-9 h-9 sm:w-14 sm:h-14 flex justify-center items-center text-center text-2xl"
                                                                    style={{lineHeight: '3rem'}}>&gt;</div>
                                                                <div className="text-xs sm:text-base font-semibold w-[140px] sm:w-auto">
                                                                    Join Memo Discord
                                                                </div>
                                                            </div>
                                                            {
                                                                discord ?
                                                                    <button
                                                                        className="bg-blue-600 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium sm:py-2 sm:px-4 rounded-3xl"
                                                                        type="button" disabled>
                                                                        Joined
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className="bg-blue-400 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium hover:text-white hover:bg-blue-600 px-2 sm:py-2 sm:px-4 rounded-3xl"
                                                                        onClick={joinDiscord} type="button">
                                                                        Join
                                                                    </button>
                                                            }
                                                        </div>
                                                        <div className="flex flex-row text-white mb-10 items-center">
                                                            <div className="flex-grow flex flex-row gap-3 sm:gap-5 items-center">
                                                                <div
                                                                    className="rounded-md bg-blue-400 text-white w-9 h-9 sm:w-14 sm:h-14 flex justify-center items-center text-center text-2xl"
                                                                    style={{lineHeight: '3rem'}}>&gt;</div>
                                                                <div className="text-xs sm:text-base font-semibold w-[140px] sm:w-auto">
                                                                    Join Memo Telegram
                                                                </div>
                                                            </div>
                                                            {
                                                                telegram ?
                                                                    <button
                                                                        className="bg-blue-600 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium sm:py-2 sm:px-4 rounded-3xl"
                                                                        type="button" disabled>
                                                                        Joined
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className="bg-blue-400 w-20 h-10 sm:w-32 sm:h-14 text-white text-xs sm:text-base font-medium hover:text-white hover:bg-blue-600 px-2 sm:py-2 sm:px-4 rounded-3xl"
                                                                        onClick={joinTelegram} type="button">
                                                                        Join
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="flex flex-col lg:flex-row justify-between text-left">
                                                    <div className="flex flex-col items-stretch text-left">
                                                        <div
                                                            className="relative px-6 lg:px-8 mt-8 sm:mt-12 shadow-2xl rounded-xl bg-opacity-85 bg-blue-500">
                                                            <div
                                                                className="flex flex-row text-white text-xl sm:text-2xl font-bold mt-4 mb-6 sm:mt-6 sm:mb-12">
                                                                Invitates
                                                            </div>
                                                            <div className="overflow-y-auto h-[330px] sm:h-[390px] lg:w-[790px]">
                                                                {
                                                                    !inviteLoading ?
                                                                        <div className="flex flex-row items-center justify-center m-auto">
                                                                            <ThreeDot color="#32cd32" size="medium" textColor=""/>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {
                                                                                invite.length === 0 ?
                                                                                    <div className="text-white text-sm sm:text-lg">
                                                                                        Invite friends to get points
                                                                                        <div className="flex flex-row text-white mb-10 items-center">
                                                                                            <div
                                                                                                className="flex-grow flex flex-row gap-3 sm:gap-5 items-center">
                                                                                                <div
                                                                                                    className="rounded-md bg-blue-400 text-white w-9 h-9 sm:w-14 sm:h-14 flex justify-center items-center text-center text-2xl"
                                                                                                    style={{lineHeight: '3rem'}}>&gt;</div>
                                                                                                <div
                                                                                                    className="text-xs sm:text-base font-semibold w-[140px] sm:w-auto">
                                                                                                    Get Invite Code
                                                                                                </div>
                                                                                            </div>
                                                                                            <ClipboardButton name={inviteCode} text={inviteCode}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    [...invite]
                                                                                        .sort((a, b) => b.points - a.points)
                                                                                        .map((row, index) => (
                                                                                            <div
                                                                                                className="flex flex-row text-white mb-10 items-center">
                                                                                                <div
                                                                                                    className="flex-grow flex flex-row gap-3 sm:gap-5 items-center">
                                                                                                    <div
                                                                                                        className="rounded-md bg-blue-400 text-white w-9 h-9 sm:w-14 sm:h-14 flex justify-center items-center text-center text-2xl"
                                                                                                        style={{lineHeight: '3rem'}}>&gt;</div>
                                                                                                    <div
                                                                                                        className="hidden sm:block text-xs sm:text-base font-semibold w-[150px] sm:w-auto">
                                                                                                        Invite Available
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex flex-row gap-3 sm:gap-5 items-center">
                                                                                                    <div className="text-sm sm:text-base">
                                                                                                        {row.nickName.length > 15 ? row.nickName.substring(0, 15) + '...' : row.nickName}
                                                                                                    </div>
                                                                                                    <ClipboardButton name='Copy Invite'
                                                                                                                     text={row.nickName}/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                            <div class="sticky inset-0">
                                                                <div
                                                                    class="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-b from-transparent to-blue-500 to-80%"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row items-stretch text-left w-[330px]">
                                                        <div
                                                            className="relative px-6 lg:px-8 mt-8 sm:mt-12 shadow-2xl rounded-xl bg-opacity-85 bg-blue-500">
                                                            <div className="mb-8">
                                                                <div className="flex flex-row text-white text-xl sm:text-2xl font-bold mt-8">
                                                                    How Invitates Work?
                                                                </div>

                                                                <p className="mt-8 mb-8 text-sm leading-normal text-white">
                                                                    You get points when your invites earn points and when their invites earn points.
                                                                    You get +16% bouns points when your invites earn points and +8% when their invites
                                                                    earn points.
                                                                </p>

                                                                <table>
                                                                    <tr>
                                                                        <td className='w-20 py-2'>
                                                                            <p className="text-sm sm:text-base font-medium leading-normal text-white">You</p>
                                                                        </td>
                                                                        <td className='flex flex-row justify-around w-32 py-3'>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td className='text-white text-right w-16 py-2'>
                                                                            <p className="text-base sm:text-lg font-medium leading-normal text-white"></p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td></td>
                                                                        <td className='flex flex-row justify-around w-32'>
                                                                            <AiOutlineArrowDown className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='w-20 py-2'>
                                                                            <p className="text-sm sm:text-base font-medium leading-normal text-white">Invites</p>
                                                                        </td>
                                                                        <td className='flex flex-row justify-around w-32 py-3'>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td className='text-white text-right w-16 py-2'>
                                                                            <p className="text-base sm:text-lg font-medium leading-normal text-white">+10</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td></td>
                                                                        <td className='flex flex-row justify-around w-32'>
                                                                            <AiOutlineArrowDown className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='w-20 py-2'>
                                                                            <p className="text-sm sm:text-base font-medium leading-normal text-white"></p>
                                                                        </td>
                                                                        <td className='flex flex-row justify-around w-32 py-3'>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td className='text-white text-right w-16 py-2'>
                                                                            <p className="text-base sm:text-lg font-medium leading-normal text-white"></p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='w-20 py-2'>
                                                                            <p className="text-sm sm:text-base font-medium leading-normal text-white">Invites</p>
                                                                        </td>
                                                                        <td className='flex flex-row justify-around w-32 py-3'>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td className='text-white text-right w-16 py-2'>
                                                                            <p className="text-base sm:text-lg font-medium leading-normal text-white">+5</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='w-20 py-2'>
                                                                            <p className="text-sm sm:text-base font-medium leading-normal text-white"></p>
                                                                        </td>
                                                                        <td className='flex flex-row justify-around w-32 py-3'>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                            <AiOutlineUser className='w-5 h-5 sm:w-7 sm:h-7 text-white'/>
                                                                        </td>
                                                                        <td className='text-white text-right w-16 py-2'>
                                                                            <p className="text-base sm:text-lg font-medium leading-normal text-white"></p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </>)
                                :
                                <div className="pt-10 lg:pt-20 overflow-hidden relative h-full flex justify-center">
                                    <img src={webConfig.image.loading} alt="harry"></img>
                                </div>
                    }
                </div>
            </div>
        </div>
    );
}
