export const SwapError = {
    "201": "The equipment source does not exist",
    "202": "Decimal digits of deposit amount are too long",
    "203": "Failed to obtain the deposit address",
    "204": "The service charge method does not exist",
    "205": "The service charge method cannot be set repeatedly",
    "207": "Address type does not exist",
    "209": "The destination receiving address cannot be set repeatedly",
    "210": "The return to original currency address cannot be set repeatedly",
    "212": "The address type is set incorrectly",
    "213": "Illegal request",
    "214": "Currency does not exist",
    "215": "The address is illegal",
    "216": "Illegal amount and quantity",
    "217": "Insufficient account balance",
    "218": "This currency does not support exchange, please select again",
    "266": "Cannot exceed 2 digits after the decimal point",
    "270": "The amount of the coin cannot exceed 4 digits after the decimal point",
    "271": "The currency amount of the currency can only be an integer",
    "279": "The amount of the coin cannot exceed 3 digits after the decimal point",
    "311": "You've reached the daily trade amount limit!",
    "800": "success",
    "900": "Incomplete request parameters",
    "902": "System starting",
    "903": "Authentication failed",
    "904": "Message decryption failed",
    "905": "Message processing error",
    "906": "System processing exception",
    "907": "Required field is blank",
    "908": "Error in sending message",
    "909": "Message verification failed",
    "910": "Received successfully",
    "911": "System processing error",
    "912": "The system does not have this order data",
    "913": "The deposit currency does not exist",
    "914": "The receiving currency does not exist",
    "915": "Deposit currency and receipt currency cannot be the same",
    "916": "The destination address is illegal",
    "917": "Illegal refund address",
    "918": "Destination address and refund address cannot be the same",
    "919": "Illegal deposit amount",
    "920": "Illegal amount in received currency",
    "921": "Deposit amount is not within the range",
    "1145": "Address or IP at risk",
    "1146": "The destination address is not authorized",
    "1147": "You have reached the daily upper limit for $Cube. The swap is temporarily unavailable.",
    "1154": "Insufficient liquidity"
}
