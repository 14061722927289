import axios from "axios";
import webUtil from "./webUtil";


const baseURL = webUtil.api;

export const bindWallet = async (address) => {
    const url = `${baseURL}/api/user/wallet/bind`;
    const data = {
        "walletAddress": address
    };
    const headers = {
        "Content-Type": "application/json"
    }
    try {
        const response = await axios.post(url, data, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getUserInfo = async (uid, token) => {
    const url = `${baseURL}/api/user/info`;
    const headers = {
        "uid": uid,
        "token": token
    };
    try {
        const response = await axios.get(url, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const editUserInfo = async (uid, token, data) => {
    const url = `${baseURL}/api/user/update`;
    const headers = {
        "uid": uid,
        "token": token,
        "Content-Type": "application/json"
    };
    try {
        const response = await axios.post(url, data, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const bindInvite = async (uid, token, data) => {
    const url = `${baseURL}/api/invite/bind`;
    const headers = {
        "uid": uid,
        "token": token,
        "Content-Type": "application/json"
    };
    try {
        const response = await axios.post(url, data, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const recentList = async () => {
    const url = `${baseURL}/api/invite/recent`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const rankList = async () => {
    const url = `${baseURL}/api/invite/rank`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const myList = async (uid, token) => {
    const url = `${baseURL}/api/invite/mylist`;
    const headers = {
        "uid": uid,
        "token": token
    };
    try {
        const response = await axios.get(url, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const pointRecord = async (uid, token) => {
    const url = `${baseURL}/api/record/list`;
    const headers = {
        "uid": uid,
        "token": token
    };
    try {
        const response = await axios.get(url, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const addPoint = async (uid, token, action) => {
    const url = `${baseURL}/api/record/add`;
    const data = {
        "action": action
    };
    const headers = {
        "uid": uid,
        "token": token
    };
    try {
        const response = await axios.post(url, data, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getAuth = async (uid, token, platform) => {
    const url = `${baseURL}/api/auth/get?platform=` + platform;
    const data = {
        "platform": platform
    };
    const headers = {
        "uid": uid,
        "token": token
    };
    try {
        const response = await axios.get(url, {headers});
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
